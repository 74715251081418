import dashboard from "./menu/dashboard";
import configurationHeader from "./menu/configurationHeader";
import user from "./menu/user";
import employee from "./menu/employee";
import managementHeader from "./menu/managementHeader";
import item from "./menu/item";
import invoice from "./menu/invoice";

export default [
  ...dashboard,
  ...managementHeader,
  ...item,
  ...invoice,
  ...configurationHeader,
  ...employee,
  ...user,
]
